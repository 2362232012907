<template>

    <transition name="flip">
        <div class="elevation-4 card"
             :class="{'card-removed elevation-0 grey lighten-4' : card.removed, 'faded': !card.flipped}"
             :key="card.flipped">
            <div v-if="!card.removed" class="d-flex justify-center align-center fill-height">
                <div v-if="card.flipped || flipped" :key="`card-${card.id}-turned`">
                    <component :is="card.id" :size="avatarSize" class="avatar"/>
                </div>
                <v-img v-else :src="require('@/assets/InkInc.png')" :height="avatarSize" :width="avatarSize"/>
            </div>
        </div>
    </transition>
</template>

<script>
import {Card} from "../plugins/memorygame";

export default {
    name: "MemoryCard",
    data() {
        return {}
    },
    props: {
        card: {type: Card, required: true},
        flipped: {type: Boolean, default: false},
    },
    methods: {
        clicked() {
            this.$emit('click')
        }
    },
    computed: {
        avatarSize() {
            return this.$vuetify.breakpoint.smAndDown ? 64 : 128;
        },
    }
}
</script>

<style scoped>
/*.card {*/
/*    border: 8px solid #000055;*/
/*    border-radius: 20px;*/
/*}*/

/*.card-back {*/
/*    background-size: 96px 96px;*/
/*    background-image: url(~@/assets/InkInc.png);*/
/*    background-position: center center;*/
/*    background-origin: content-box;*/
/*}*/
</style>

<style>

.card {
    border: 8px solid #000055;
    width: 150px;
    height: 175px;
    border-radius: 7px;
    display: block;
    cursor: pointer;
    position: relative;
    will-change: transform;
}

.card .v-image {

}

.sm .card {
    border: 1px solid #000055;
    width: 75px;
    height: 90px;
}

.card.card-removed {
    border: none;
}

.card.faded .v-image{
    opacity: 0.2;
}

li:hover {
    transform: scale(1.1);
}

.flip-enter-active {
    transition: all 1.0s ease;
}

.flip-leave-active {
    display: none;
}

.flip-enter, .flip-leave {
    transform: rotateY(180deg);
    opacity: 0;

}

</style>
