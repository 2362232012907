<template>
    <v-container>
        <v-row no-gutters>
            <v-col class="d-flex justify-start px-2" cols="6">
                <memory-score :score="humanScore" :my-turn="humanTurn" icon="mdi-emoticon-happy-outline"/>
            </v-col>
            <v-col class="d-flex justify-end px-2" cols="6">
                <memory-score :score="aiScore" :my-turn="!humanTurn" character="CircleMonkeyAnimation"/>
            </v-col>
        </v-row>
        <ul class="flashcard-list justify-center" v-if="ready">
            <li v-on:click="cardClicked(index)" v-for="(card, index) in board.cards" :key="`card-${index}`">
                <memory-card :card="card"/>
            </li>
        </ul>

    </v-container>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';
import {Board} from "../plugins/memorygame";
import MemoryCard from "@/components/MemoryCard.vue";
import Vue from "vue";
import MemoryScore from "@/components/MemoryScore.vue";

export default {
    name: "Memory",
    // eslint-disable-next-line vue/no-unused-components
    components: {MemoryScore, MemoryCard, AnimatedNumber},
    data: () => ({
        humanTurn: true,
        humanScore: 0,
        aiScore: 0,
        rows: 4,
        cols: 6,
        board: null,
        ready: false,
        chosen: [],
        showMove: false
    }),
    mounted() {
        this.board = Vue.observable(new Board(this.rows, this.cols));
        this.board.dealCards(['CircleMonkeyAnimation', 'TriangleSharkAnimation', 'SquarePandaAnimation'])
        this.ready = true;
    },
    methods: {
        getCard(row, col) {
            return Vue.observable(this.board.getCard(row, col));
        },
        cardClicked(index) {
            let card = this.board.cards[index];
            if (card.removed) return;

            let flipped = card.flipped;
            if (this.chosen.length >= 2) {
                this.moveComplete();
            }

            if (flipped) return;
            if (this.chosen.length >= 2) return;

            this.board.cards[index].flip();
            this.chosen.push(card);
            if (this.chosen.length === 2 && this.chosen[0].id === this.chosen[1].id) {
                if (this.humanTurn) {
                    this.humanScore++;
                } else {
                    this.aiScore++;
                }
            }
        },
        moveComplete() {
            if (this.chosen[0].id === this.chosen[1].id) {
                this.chosen[0].remove();
                this.chosen[1].remove();
            } else {
                this.humanTurn = !this.humanTurn;
            }
            this.chosen[0].flip();
            this.chosen[1].flip();
            this.chosen = [];
        }
    },
    computed: {
        width() {
            return 150;
        },
        height() {
            return 200;
        }
    },

};
</script>

<style>
ul.flashcard-list {
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
}

li.sm {
    padding: 10px 10px;
}

li {
    list-style-type: none;
    padding: 5px 5px;
    transition: all 0.3s ease;
}

</style>
