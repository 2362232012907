<template>
    <div :class="{'yellow lighten-3': myTurn}" class="d-flex players-score align-center">
        <v-icon :size="avatarSize" v-if="icon" color="yellow darken-1">{{ icon }}</v-icon>
        <animated-number
                class="score ink-inc"
                :value="score * 10"
                :formatValue="formatScore"
                :duration="700"/>
        <component :is="character" v-if="character" :size="avatarSize"/>
    </div>

</template>

<script>
import padStart from "lodash/padStart";
import AnimatedNumber from "animated-number-vue";
import CircleMonkeyAnimation from "@/components/CircleMonkeyAnimation.vue";

export default {
    name: "MemoryScore",
    components: {AnimatedNumber, CircleMonkeyAnimation},
    props: {
        myTurn: {type: Boolean, required: true},
        score: {type: Number, required: true},
        icon: {type: String, default: ''},
        character: {type: String, default: ''}
    },
    methods: {
        formatScore(value) {
            return padStart(Math.floor(value), 4, '0');
        }
    },
    computed: {
        avatarSize() {
            return this.$vuetify.breakpoint.smAndDown ? 48 : 96;
        }
    }
}
</script>

<style scoped>
.d-flex.players-score {
    border-radius: 10px;
    margin: 8px;
    margin-bottom: 16px;
    border: 4px solid #000055 !important;
    border-color: #000055 !important;
    padding: 8px;
}

.d-flex.players-score .score {
    min-width: 250px;
}

.sm .d-flex.players-score .score {
    min-width: 80px;
}

.score {
    font-size: 96px;
    line-height: 96px;
}

.sm .score {
    font-size: 32px;
    line-height: 32px;
}


</style>
