import shuffle from 'lodash/shuffle';
import Vue from "vue";

class Card {

    id = ''
    flipped = false
    removed = false

    constructor(id) {
        this.id = id
    }

    flip() {
        this.flipped = !this.flipped
    }

    remove() {
        this.removed = true
    }
}

class Location {
    row = 0
    col = 0

    constructor(row, col) {
        this.row = row
        this.col = col
    }
}

class Board {
    rows  = 4
    cols = 4

    randomCells = [];
    cards = [];

    constructor(rows, cols) {
        let cells = [];
        this.rows = rows
        this.cols = cols
        for (let i = 0; i < rows * cols; i++) {
            cells.push(i)
        }
        this.randomCells = shuffle(cells)
    }

    dealCards(cardTypes) {
        this.cards = Vue.observable(new Array(this.rows * this.cols))
        let cardTypeIdx = 0;
        for (let i = 0; i < this.rows * this.cols; i++) {
            this.cards[this.randomCells[i]] = Vue.observable(new Card(cardTypes[Math.floor(cardTypeIdx)]))
            cardTypeIdx += 0.5;
            if (Math.floor(cardTypeIdx) >= cardTypes.length) {
                cardTypeIdx = 0;
            }
        }
    }

    getCard(row, col) {
        return this.cards[this.getCardIndex(row, col)]
    }

    getCardIndex(row, col) {
        return (row - 1) * this.cols + (col - 1)
    }
}


export {
    Location,
    Card,
    Board
}
